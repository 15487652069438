/* Default styles */
.sliding-banner-container {
  background-color: #f2f2f2; /* Light grey background */
  padding: 7px;
  height: 500px; /* Fixed container height */
  overflow: hidden; /* Hide overflow to ensure only the active slide is visible */
  display: flex; /* Utilize flexbox for alignment */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  position: relative; /* Needed for absolute positioning of child elements */
}

.sliding-banner, .banner-item {
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  margin: 0;
  padding: 0;
}

.sliding-banner {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 15px; /* Rounded corners for aesthetics */
  overflow: hidden; /* Ensures content respects the container's border radius */
}

.banner-item {
  flex: 0 0 100%; /* Each slide takes up the full width of the container */
  height: 100%; /* Ensures each item fills the container's height */
  position: relative; /* Positioning context for contents */
  display: flex; /* Flexbox for internal alignment */
  justify-content: center; /* Horizontally centers content within each banner item */
  align-items: center; /* Vertically centers content */
  transition: transform 1s ease; /* Smooth transition for slide movement */
}

.banner-item img {
  width: 100%; /* Full width of its container */
  height: 100%; /* Adjusted to cover the full height */
  object-fit: calc(); /* Ensures image covers space without distortion */
}

.error-message, .no-banners-message {
  text-align: center; /* Center-aligned text for messages */
  width: 100%; /* Full width ensures centering is effective */
  color: #ff0000; /* Error color for visibility */
  
}

/* Media query adjustments for smaller screens */
@media (max-width: 768px) {
  .sliding-banner-container {
    height: 225px !important; /* Adjusted height for smaller screens to maintain aspect ratio */
    padding: 0;
  }
  
  .sliding-banner, .banner-item {
    height: 100%;
  }
  
  .banner-item img {
    width: 100%; /* Full width to maintain aspect ratio */
    height: 100%; /* Height adjusted to maintain aspect ratio */
    object-fit: cover; /* Cover ensures aspect ratio is maintained without distortion */
    object-position: center; /* Centers the image to ensure best parts are visible */
  }
}
