.styledTable {
  width: 75%; /* Reduce the table width to 75% of its container for desktop */
  margin: 0 auto; /* Center the table within its container */
  border-collapse: collapse;
}


.styledTable {
  width: 100%;
  border-collapse: collapse;
}


.sticky-header {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  background-color: #FFF; /* Ensure readability */
  z-index: 2; /* Above other content */
  text-align: center;
  width: 100%;
}

.sticky-header h2 {
  margin: 0;
  padding: 10px 0;
}

.styledTable thead th {
  position: sticky;
  top: 50px; /* Set this to the height of your .sticky-header */
  background-color: #0cbb41;
  z-index: 1;
  
}

  .styledTable thead tr {
    background-color: #009879;
    color: #ffffff;
    text-align: left;
  }
  
  .styledTable th,
  .styledTable td {
    padding: 12px 15px;
  }
  
  .styledTable tbody tr {
    border-bottom: 1px solid #dddddd;
  }
  
  .styledTable tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
  
  .styledTable tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
  }
  
  .styledTable tbody tr.active-row {
    font-weight: bold;
    color: #009879;
  }
  
  .styledTable tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  /* Image styling */
  .styledTable img {
    width: 50px;
    height: auto;
    border-radius: 5px; /* Optional: For rounded corners */
  }
  
  /* Responsive table adjustments */
  @media (max-width: 768px) {
    .styledTable thead {
      display: none;
    }
  
    .styledTable, .styledTable tbody, .styledTable tr, .styledTable td {
      display: block;
      width: 100%;
    }
  
    .styledTable tr {
      margin-bottom: 15px;
    }
  
    .styledTable td {
      text-align: right;
      padding-left: 50%;
      position: relative;
      text-align: right;
    }
  
    .styledTable td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      font-weight: bold;
      text-align: left;
    }
  }
  

  @media (max-width: 768px) {
    .styledTable thead {
      display: none;
    }
  
    .styledTable, .styledTable tbody, .styledTable tr, .styledTable td {
      display: block;
      width: 100%;
    }
  
    .styledTable td {
      text-align: left; /* Ensures text alignment is consistent */
      padding-left: 15px; /* Adjusts padding to provide space for labels */
      position: relative;
    }
  
    .styledTable td::before {
      content: attr(data-label); /* Uses the data-label attribute for labels */
      position: absolute;
      left: 0;
      font-weight: bold;
      text-align: left;
      top: 0; /* Aligns label to the top of the cell */
      padding: 0 15px; /* Adds padding around the label */
    }
  
    /* Responsive styling for images */
    .styledTable img {
      max-width: 100%; /* Allows images to scale down within their blocks */
      height: auto; /* Maintains the aspect ratio */
      display: block; /* Ensures images are block-level for proper scaling */
      margin: 10px 0; /* Adds some vertical space around images */
    }
  }
  