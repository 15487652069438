/* footer.css */

body, html {
  height: 100%;
  overflow-x: hidden; /* This is okay */
  overflow-y: auto; /* Ensure vertical scrolling is allowed */
}

.footer {
  display: flex;
  background-color: #000;
  color: #fff;
  text-align: left;
  height: 150px; /* Adjust the height as necessary */
  width: 100%; /* Ensure the footer extends full width */
  border-radius: 7px;
  padding-bottom: 15px;
  z-index: 1000; /* Adjust as necessary */
  
}




.footer-content {
  display: flex;
  justify-content: space-between;
}

.footer, .footer-content {
  display: flex;
  justify-content: space-between; /* This will space out the child elements equally */
  align-items: center; /* This will vertically center the child elements */
  width: 100%; /* Ensures the footer uses the full width */
}


.footer-section > * {
  text-align: center; /* Centers text inside the child elements */
}



.footer-section1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers content vertically */
  align-items: flex-end; /* Aligns content to the right */
  margin-right: 30px;
  height: 100px; /* Example height; adjust based on your design */
}


.footer-section2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers content vertically */
  align-items: flex-start; /* Aligns content to the right */
}
.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin: 5px 0;
  text-align: left;
  padding-left: 40px;
  padding-bottom: 3px;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  font-size: 0.9em;
  display: block;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-contact p,
.footer-bottom p,
.footer-auth a {
  margin: 5px 0;
  font-size: 0.9em;
  text-align: center;
  margin-top: 50px;
}

.footer-auth a {
  color: #fff;
  text-decoration: none;
  
}

.footer-auth a:hover {
  text-decoration: underline;
}


@media (max-width: 768px) {
  .footer {
    padding: 10px 5px; /* Reduce overall footer padding */
    min-height: 220px;
  }

  .footer-content {
    justify-content: space-between; /* Adjust content spacing */
  }

  .footer-section1, .footer-section2 {
    padding: 5px; /* Reduce padding inside each section for a more compact look */
    margin: 0 2px; /* Minimize margin between sections */
  }

  .footer-links li, 
  .footer-contact p, 
  .footer-bottom p, 
  .footer-auth a {
    font-size: 12px; /* Reduce font size for a more compressed text display */
    padding-left: 0; /* Remove unnecessary padding */
    margin: 2px 0; /* Reduce margin between text lines for a tighter layout */
  }

  .footer-links li {
    margin-right: 10px; /* Adjust right margin for list items to keep them tighter */
  }

  .footer-links, 
  .footer-contact, 
  .footer-bottom, 
  .footer-auth {
    flex-direction: column; /* Stack link items vertically if horizontal space is tight */
    align-items: center; /* Center-align items for consistency */
  }
}
