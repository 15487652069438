

.product-list-container, .product-list {
  box-sizing: border-box;
}

.product-item {
  display: flex;
  position: relative; 
  border-bottom: 1px solid #ccc;
  padding: 20px 0; /* Adjusted padding */
}

.image-container {
  width: 100px; /* Adjusted width */
  margin-right: 20px;
}

.item-image {
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.item-details {
  flex: 1;
}

.item-name {
  font-size: 18px;
  margin-bottom: 5px;
}

.item-price {
  font-size: 16px;
  margin-bottom: 5px;
}

.selected-option {
  font-size: 16px;
  margin-bottom: 5px;
}

.quantity-input input {
  width: 10px; /* Adjusted width */
  padding: 5px;
  margin-right: 10px;
}

.custom-text-input {
  
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.product-item .item-details .custom-text-input {
  width: 60% !important;
}




.image-upload {
  align-self: flex-end; /* Align the button to the end of the flex container */
  margin-top: 5px; /* Add some margin to separate it from other elements */
  margin-left: 250px; /* Move the button to the right by setting margin-left to auto */
}






.delivery-address-input input {
  width: 80%;
  padding: 5px;
  margin-bottom: 10px;
}

.total-amount {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
}

.make-payment-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
