.signup-form-container {
  max-width: 300px; /* Increased from 600px to 800px */
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}

  
  .signup-form-container h2 {
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input[type="email"],
  input[type="password"],
  input[type="text"],
  input[type="tel"],
  select,
  input[type="date"] {
    width: 100%;
    height: 30px;
    padding: 5px;
    margin-bottom: 10px; /* Add space below each input */
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  button[type="submit"] {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .signup-form-container .form-group:last-child {
    margin-bottom: 0;
  }
  
  .signup-form-container .form-group label,
  .signup-form-container button[type="submit"] {
    font-size: 14px;
  }
  
  .signup-form-container .form-group select {
    height: 34px;
  }
  
  .signup-form-container .form-group select option:first-child {
    color: #999;
  }
  
  .signup-form-container .form-group select:focus {
    outline: none;
  }
  
  .signup-form-container .form-group input[type="date"]::-webkit-inner-spin-button,
  .signup-form-container .form-group input[type="date"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .signup-form-container .form-group input[type="date"] {
    height: 34px;
  }
  
  .signup-form-container .form-group input[type="date"]:focus {
    outline: none;
  }
  
  .signup-form-container .form-group input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
  }
  