/* PasswordResetRequest.css */

/* General container styles */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f7f6; /* Soft background color */
  margin-bottom: 25px;
  padding-top: 15px;
  padding-bottom: 25px;
  
}

/* Card styling */
.card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 10px;

}

.password-reset-card {
  background-color: #ffffff; /* Pure white background */
}

.password-reset-header {
  background-color: #007bff; /* Primary color */
  color: #ffffff; /* White text color */
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 1.25rem; /* Larger font size for headers */
}

/* Form element styling */
.form-label {
  font-weight: 500; /* Semi-bold labels for better readability */
  color: #333; /* Darker label color for contrast */
}

.form-control {
  border-radius: 5px; /* Slightly rounded corners */
  border: 1px solid #ced4da; /* Standard border color */
  padding: 0.5rem 1rem; /* Comfortable padding */
  max-width: 95%;
  
}

.form-control:focus {
  border-color: lightgreen; /* Highlight focus with primary color */
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Subtle focus shadow */
}

/* Button styling */
/* Adjust the button padding to reduce height */
.btn {
  padding: 0.25rem 1.5rem; /* Reduced vertical padding */
  border-radius: 5px;
  font-size: 10px;
  max-width: 36%;
  letter-spacing: 0.05rem;
  transition: background-color 0.2s, color 0.2s;
  margin-left: 32%;
}

/* Secondary button styles */
.btn-secondary {
  background-color: #6c757d;
  color: #ffffff;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

/* Primary button styles */
.btn-primary {
  background-color: #007bff;
  color: #ffffff;
}

.btn-primary:hover {
  background-color: #0069d9;
}

/* Message styling */
.success-message, .error-message {
  text-align: center;
  font-size: 1rem;
  padding: 0.5rem;
}

.text-success {
  color: #28a745; /* Success color */
}

.text-danger {
  color: #dc3545; /* Error color */
}
