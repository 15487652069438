.product-categories-container {
  background-color: white;
  padding-top: 7px;
  display: flex; /* Enables flexbox properties */
  justify-content: center; /* Centers the child horizontally */
  overflow-x: hidden; /* Hides horizontal overflow, allowing .product-categories to manage it */
  height: 160px;
}



.product-categories {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  background-color: white;
  padding: 15px 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.1);
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Prevent vertical scrolling */
  white-space: nowrap;
  max-width: 800px; /* Limit width to trigger overflow scrolling */
  margin: 0 auto; /* Center within .product-categories-container if desired */
  position: relative; /* Needed for the pseudo-element to position correctly */
  margin-bottom: 8px;
}




.product-categories-container *, .product-categories *, .category *, .category button {
  cursor: pointer !important;
}


.category {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 150px !important; /* Set width of each category */
  text-align: center;
  cursor: pointer;
  height: 150px;
  overflow: hidden; /* Hide overflow within each category */
  margin-right: -30px !important; /* Ensure gap between items */
  flex-shrink: 0; /* Prevents the item from shrinking */
}

.category:last-child {
  margin-right: 0 !important; /* Remove right margin from the last category item */
}

.category a {
  text-decoration: none;
  color: inherit;
}

.category img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 10px;
}

.category span {
  display: block;
  margin-top: auto;
  font-size: 12px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
}

@media (max-width: 768px) {

  .product-categories-container {
   
    height: 120px;
  }
  .product-categories {
    /* Remove side margins for smaller screens to utilize more screen space */
    margin: 0;
    /* Increase or maintain max-width to potentially show more items if space allows */
    max-width: 100%; /* Allow .product-categories to expand to the full width of its container */
    padding: 10px 0; /* Adjust padding to fit smaller screens */
    height: 100px;
  }

  .category {
    width: 120px; /* Adjust width for smaller screens */
    height: 120px; /* Adjust height accordingly */
    margin-right: -30px !important; /* Adjust the gap between items for consistency */
  }

  .category img {
    width: 60px; /* Adjusted for smaller screens */
    height: 60px; /* Consistent with adjustment */
  }

  .category span {
    font-size: 11px; /* Slightly smaller font size for compact display */
    /* Adjust text alignment or other properties if needed */
  }
}
