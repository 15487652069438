.product-list-container {
  background-color: #f2f2f2; /* Light grey background */
  padding-right: 7px;
  padding-left: 7px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.product-list {
  display: flex;
  justify-content: space-around;
  background-color: white;
  flex-wrap: wrap; /* Allow items to wrap */
  gap: 20px 5px; /* First value for row gap, second value for column gap */
  padding: 15px 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}





.product-item {
  flex: 0 1 17%; /* Change flex-basis to 20% for 5 items per row */
  min-width: 150px; /* Keep the minimum width to ensure items don't get too small */
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: auto;
}

.customization-message {
  position: absolute;
  bottom: 10px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 128, 0, 0.8); /* Dark green with opacity */
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  display: none;
  font-size: 15px;
  margin-bottom: 225px;
}

.product-item:hover .customization-message {
  display: block;
}

@media screen and (max-width: 600px) {
  .product-list .product-item {
    flex-basis: 45%; /* Adjust for smaller screens */
  }
}



.product-item img {
  width: 100%; /* Full width of the container */
  height: 100px; /* Fixed height for uniformity */
  object-fit: contain; /* Ensures the full image is visible */
  margin-bottom: 10px;
  transition: transform .6s ease; /* Smooth transition for zoom effect */
}

.product-item img:hover {
  transform: scale(2); /* Zoom on hover */
  cursor: pointer; /* Changes the cursor to indicate clickable/interactive element */
}


.product-item h3 {
  margin-top: 20px;
  margin-bottom: -8px;
  font-size: 12px !important; /* Reduced font size for the product title */
}

.product-item .product-price {
  margin-bottom: 10px; /* Adjusted margin */
  font-weight: bold;
  font-size: 12px; /* Reduced font size for the price */
}


.product-item select {
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  width: 70%;
}

.product-item button {
  padding: 8px 12px;
  background-color: #136207;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  position: relative; /* Ensure the button is clickable */
  z-index: 1; /* Ensure the button is above the message */

}

.product-item button:hover {
  background-color: #45a049;
}

.product-item button:active {
  background-color: #388e3c;
  color: #fff;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-controls button {
  padding: 5px 10px;
  margin: 0 10px;
  background-color: darkblue;
  color: white;
}
