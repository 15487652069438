.sticky-nav {
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: white;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: #14e911;
  text-decoration: none;
}
.logo-image {
  height: 40px;
  width: auto;
  margin-right: 10px;
  transition: box-shadow 0.3s ease; /* Smooth transition for the box-shadow */
}

.logo-image:hover {
  box-shadow: 0 0 0 0.5px rgb(78, 4, 189); /* Simulate a border with box-shadow */
}



.search-box {
  margin-left: 20px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f2f2f2; /* Light grey background */
  border-radius: 20px; /* Rounded corners */
  height: 32px;
  
}

.search-box input[type="text"] {
  width: 300px; /* Reduced width */
  height: 30px; /* Reduced height */
  padding-top: 15px; /* Remove padding */
  border: none;
  border-radius: 20px; /* Rounded corners */
  font-size: 16px;
  text-align: center; /* Center text horizontally */
  line-height: 30px; /* Line height equal to height for vertical centering */
  background-color: #f2f2f2; /* Light grey background */
  background-position: 10px center; /* Position the icon */
  background-repeat: no-repeat; /* Prevent repeating the icon */
  outline: none; /* Remove outline on focus */
  box-sizing: border-box; /* Adjust box model to include padding and border */
  -webkit-appearance: none; /* Remove default webkit styles */
}

.search-box input[type="text"]:focus,
.search-box input[type="text"]::placeholder {
  border-color: #f2f2f2; /* Light grey border on focus */
  text-align: center;
}




.nav-links {
  display: flex;
  align-items: center;
}

/* Styles for login link */
.login-link {
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: darkblue;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.login-icon {
  margin-right: 5px;
  color: greenyellow;
}

.login-text {
  color: black;
  transition: color 0.3s ease; /* Transition for color change */
}

.login-link:hover,
.login-link:hover .login-icon,
.login-link:hover .login-text {
  color: rgb(18, 230, 18); /* Color change on hover */
}

/* Styles for cart link */
.cart-link {
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: navy; /* Default color set to black */
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.cart-link:hover,
.cart-link:hover .cart-icon {
  color: darkgreen; /* Color change on hover */
}

.cart-count {
  display: inline-block;
  margin-left: 5px;
  padding: 2px 6px;
  background-color: white;
  color: black;
  border-radius: 50%;
  font-size: 12px;
}

/* Styling for the user-welcome message */


/* Styling for the logout-link */
.logout-link {
  cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
  color: #007bff; /* Change the color to your preference */
  text-decoration: underline; /* Add underline to indicate it's a link */
}

/* Add hover effect for the logout-link */
.logout-link:hover {
  text-decoration: none; /* Remove underline on hover */
  color: #0056b3; /* Change the color on hover */
}

.user-details {
  display: flex; /* This line ensures horizontal layout */
  align-items: center; /* Vertically center align the items */
  color: #14e911;
}

.user-welcome {
  margin-right: 10px; /* Creates space between the welcome text and the icon */
  color: navy;
  
}

.user-icon-wrapper {
  position: relative; /* Needed for absolute positioning of .user-menu */
}

.user-icon {
  /* If you need to adjust the size or margins of the icon */
}

.user-menu {
  display: none;
  position: absolute;
  top: 100%; /* Position the menu right below the icon */
  right: 0; /* Align the menu to the right of the user-icon-wrapper */
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1; /* Ensure the menu is above everything else */
  white-space: nowrap; /* Prevents word wrapping */
}

.menu-item {
  color: blue; /* Changes the font color to blue */
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 0.85rem; /* Reduces the font size */
}


.user-icon-wrapper:hover .user-menu {
  display: block; /* Show the dropdown menu when hovering over the icon wrapper */
}



.menu-item:hover {
  background-color: #ddd; /* Visual feedback for hover over menu items */
}

@media (max-width: 768px) {
  .sticky-nav {
    flex-wrap: wrap; /* Allows items to wrap onto the next line as needed */
    justify-content: center; /* Centers items horizontally */
    align-items: center; /* Centers items vertically */
    padding: 5px; /* Slightly reduce padding */
  }

  .logo {
    flex: 1 1 auto; /* Allows the logo to shrink but maintain its content's width */
    /* No need to set justify-content here since .logo is a flex item, not a flex container */
  }

  .logo-image {
    height: 30px; /* Reduce the logo image size for smaller screens */
    margin: 0 auto; /* Center the logo image if it's wrapped alone on a line */
  }

  .search-box {
    flex: 1 0 auto; /* Allows the search box to grow and fill available space */
    margin: 5px auto; /* Centering margin, adjusts to auto to center the box */
    max-width: 50%; /* Limits the search box width to prevent it from taking too much space */
    /* Ensuring the search box is centered if it wraps onto its own line */
  }

  .search-box input[type="text"] {
    width: 100%; /* Ensure the input field fills its container */
  }

  .nav-links {
    flex: 1 1 100%; /* Forces nav links to take full width of a new line */
    justify-content: center; /* Centers links for better appearance on narrow screens */
    margin-top: 10px; /* Adds space between nav links and other elements */
    /* Additional centering if nav-links wrap onto their own line */
  }

  .login-link, .cart-link {
    margin: 0 10px; /* Adjusts margin for individual links for better tap targets */
    /* Ensure individual links are centered if they wrap onto their own line */
  }
}
